import { Link } from "gatsby";
import React, { useState } from "react";
import slugify from "slugify";
import { StandardImg } from "../img";
import { format } from "date-fns";
import Video from "../video";
import CardHover from "../globals/HoverCard";
import styled from "styled-components";
import { setColor, setRem } from "../../styles/styles";

const ProjectCard = styled.div`
	display: grid;
	grid-template-columns: 1fr 160px;
	grid-column-gap: 20px;
	overflow: hidden;
	text-decoration: none;
	color: black;
	font-family: serif;

	@media (max-width: 1140px) {
		grid-template-columns: 1fr;
		margin-bottom: 40px;
	}

	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}

	p {
		margin-bottom: 0.75em;
	}

	strong {
		display: block;
		font-family: sans-serif;
		font-weight: normal;
		text-align: right;
		font-size: 90%;
	}
`;

const ProjectPreview = (props, { className }) => {
	const authors = props.members.map(p => p.person.name).join(' & ')

	const [hover, setHover] = useState(false);

	let url = "";
	if (props.members[0]){
	  	url = props.members[0].person.slug?.current;
	  	// console.log(url);
	} else {
		url = slugify(authors).toLowerCase();
		console.log("!!!!!!!no person!!!!!");
	}
	  
	

	return (
		<ProjectCard
		// 右边hover之后不显示size
			onMouseOver={() => setHover(false)}
			onMouseOut={() => setHover(false)}
		>
			<CardHover
				reverse
				hover={hover}
				person={props.members[0]?.person}
			/>
			<p>
				<Link to={`/project/${props.slug.current}`}>
					<em>{props.title}</em>, Naarm/Melbourne: Victorian College of the Arts, {format(new Date(props.publishedAt), "YYYY")}
				</Link>
			</p>
				<p>
					{/* <Link to={/profile/+slugify(authors).toLowerCase()}> */}
					<Link to={/profile/+url}>
						<strong>{authors}</strong>
					</Link>
				</p>
		</ProjectCard>
	);
};

export default ProjectPreview;
