import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import CardHover from "../globals/HoverCard";
import GenericCard from "./GenericCard";

const Column = styled.div`
	padding-top: 100px;

`

export const ProfileCard = ({ slug, person, name }) => {
	const [hover, setHover] = useState(false);

	return (
		<GenericCard
			as={Link}
			to={`/profile/${slug?.current}`}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
		>
			{/* <CardHover
				hover={hover}
				person={person}
			/> */}
			{name}
		</GenericCard>
	);
};

export const ProfileCardList = ({ nodes, letter }) => {
	let profileCards = [];
	profileCards = nodes.map((node) => {
		if (node && letter === "all" || node && node.name[0].toUpperCase() === letter) {
			return <ProfileCard slug={node.slug} person={node} name={node.name} />;
		}
		return null;
	});
	return <Column>{profileCards}</Column>
};
