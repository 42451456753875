import React, { useState } from "react";
import Filter from "../components/project-list/Filter";
import Book from "./globals/Book";
import { ProjectCardList } from "./project-list/ProjectCard";
import { TextCardList } from "./project-list/TextCard";
import MagazineContainer from "./project-list/MagazineContainer";
import TextProjectsContainer from "./project-list/TextProjectsContainer";
import { StyledButton } from "./globals/Buttons";
import { setRem } from "../styles/styles";
import { ProfileCardList } from "./profile-grid/ProfileCard";

function ProjectPreviewGrid(props) {
	const [discipline, setDiscipline] = useState("all");
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState();
	const [currentSlice, setCurrentSlice] = useState(0);
	const sliceSize = 5;

	const handleFilterChange = (e, filtertype) => {
		switch (filtertype) {
			case "discipline":
				if (discipline !== e.target.value)
					setDiscipline(e.target.value);
				else setDiscipline("all");
				break;
			default:
				break;
		}
	};

	const handleSearchTerm = (e) => {
		setSearchTerm(e.target.value);
	};

	React.useEffect(() => {
		const results = props.nodes.filter((project) =>
			project.title?.toLowerCase().includes(searchTerm)
		);
		setSearchResults(results);
	}, [props.nodes, searchTerm]);

	let nodes = props.nodes;
	if (searchResults && searchResults.length > 0) nodes = searchResults;

	/* Return 2 arrays, first is the project previes second is the project name cards */
	const projectComponents = ProjectCardList({
		nodes: nodes,
		discipline: discipline,
	});
	const projectPreviews = projectComponents[0];
	const projectNames = projectComponents[1];
	const textProjects = TextCardList({
		nodes: nodes,
		discipline: discipline,
	});

	const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
	const allPeople = nodes.map(n => n.members[0] ? n.members[0].person : false)
	const people = uniqueArray(allPeople)

	return (
		<>
			<Book>
				<MagazineContainer
					names={projectNames}
					projects={projectPreviews}
					className="left"
				/>
				{/* <TextProjectsContainer
					projects={textProjects}
					className="right"
				/> */}
				{/* <ProfileCardList nodes={people} letter="all" /> */}
			</Book>
			<Filter
				title="Disciplines"
				filters={props.filters}
				onClickFunction={handleFilterChange}
				selected={discipline}
			/>

	
    {false &&
			<>
			<StyledButton
				disabled={currentSlice - 2 * sliceSize < 0 ? true : false}
				onClick={() => setCurrentSlice(currentSlice - 2 * sliceSize)}
				padding={setRem(3)}
				style={{
					display: "inline-flex",
					position: "absolute",
					top: "50px",
					left: "80px",
				}}
			>
				Previous Page
			</StyledButton>
			<StyledButton
				disabled={
					currentSlice + 1 > projectComponents.length ? true : false
				}
				onClick={() => setCurrentSlice(currentSlice + 2 * sliceSize)}
				padding={setRem(3)}
				style={{
					display: "inline-flex",
					position: "absolute",
					top: "70px",
					left: "80px",
				}}
			>
				Next Page
			</StyledButton>
			</>
    }
		</>
	);
}

ProjectPreviewGrid.defaultProps = {
	title: "",
	nodes: [],
	browseMoreHref: "",
};

export default ProjectPreviewGrid;
