import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

export const query = graphql`
	query ProjectsPageQuery {
		projects: allSanityProject(
			sort: { fields: [publishedAt], order: DESC }
		) {
			edges {
				node {
					id
					videoURL
					publishedAt
					mainImage {
						asset {
							_id
							metadata {
								dimensions {
									aspectRatio
								}
							}
						}
						alt
					}
					otherImages {
						asset {
							_id
							metadata {
								dimensions {
									aspectRatio
								}
							}
						}
					}
					title
					_rawExcerpt
					slug {
						current
					}
					members {
						_key
						person {
							image {
								crop {
									_key
									_type
									top
									bottom
									left
									right
								}
								hotspot {
									_key
									_type
									x
									y
									height
									width
								}
								asset {
									_id
								}
							}
							name
							slug{
								current
							}
							_rawBio
							_rawResearchSummaryShort
							institution {
								name
								url
							}
							program {
								name
							}
							email
							facebook
							instagram
							linkedIn
							twitter
							vimeo
							keywords {
								value
							}
						}
						roles
					}
				}
			}
		}
		disciplines: allSanityDiscipline(sort: { fields: name }) {
			edges {
				node {
					name
					id
				}
			}
		}
	}
`;

const ProjectsPage = (props) => {
	const { data, errors } = props;
	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		);
	}
	const projectNodes =
		data &&
		data.projects &&
		mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs);

	const disciplineFilters = (data || {}).disciplines
		? mapEdgesToNodes(data.disciplines)
		: [];
	return (
		<Layout>
			<SEO title="Projects" />
			<Container>
				{projectNodes && projectNodes.length > 0 && (
					<ProjectPreviewGrid
						nodes={projectNodes}
						// Hide right side column
						// filters={disciplineFilters}
						filters={''}
					/>
				)}
			</Container>
		</Layout>
	);
};

export default ProjectsPage;
