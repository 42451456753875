import React from "react";
import { StyledButton } from "../globals/Buttons";
import { setColor } from "../../styles/styles";
import styled from "styled-components";

/* May need to improve grid rows so that they are only as long as their content */
const FilterList = styled.div`
	bottom: 0;
	right: 0;
	z-index: 1;
	position: fixed;
	box-sizing: border-box;
	display: flex;
	border-bottom: 1px dotted;
	padding: 20px 20px 20px 0;
	transform: translateY(56px) rotate(90deg) translateZ(0);
	transform-origin: 100% 0%;

  @media (max-width: 1140px) {
		display: none;
	}
`

const DisciplineTab = styled(StyledButton)`
/* 右边栏位 */
	position: relative;
	display: block;
	padding: 5px;
	font-size: 14px;
	color: black;
	/* margin-left: 2px; */
	font-family: serif;
	text-decoration: ${ props => props.selected ? 'none' : 'underline' };

	&:hover {
		text-decoration: none;
	}

	//height: 30px;
	//width: ${(props) => (props.width ? `${props.width}px` : "640px")};
	//border: 1px solid black;
	//bottom: 30px;
`

/* Rotated tabs on the side of the book used to filter the projects on their discipline */
const Filter = ({ filters, onClickFunction, selected }) => {
	const height = 400;
	const rowHeight = height / filters.length;
	return (
		<FilterList height={height} numRows={filters.length}>
			{filters &&
				filters.map((node) => (
					<DisciplineTab
						key={node.id}
						onClick={(e) => onClickFunction(e, "discipline")}
						value={node.name}
						selected={selected === node.name}
						filtertype="discipline"
						width={rowHeight}
					>
						{node.name}
					</DisciplineTab>
				))}
		</FilterList>
	);

};

export default Filter;
