import styled from "styled-components";
import { setColor, setRem } from "../../styles/styles";

const GenericCard = styled.div`
  color: black;
  display: flex;
  text-decoration: none;
  margin-bottom: 0.25em;

  &:hover {
    color: lightgray; // 添加你想要的hover效果样式
  }
`;

export default GenericCard;
