/** @format */

import styled from "styled-components";
import React from "react";

const Container = styled.div`
	.projects {
		padding: 100px 0;
	}
`;

/*
const Container = styled.div`
  width: 90%;
  display: grid;
  grid-area: left;
  grid-template-rows: 3% 1fr;
  margin-top: 20px;
  margin-left: 30px;
`;
*/

const TextProjectsContainer = ({ names, projects }) => {
	return (
		<Container>
			<div className="projects">{projects}</div>
		</Container>
	);
};

export default TextProjectsContainer;
