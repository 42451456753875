import React from "react";
import TextProjectPreview from "./textProjectPreview";

/* Returns a list of project name tiles or project previews depending on the argument */
/* TODO needs a refactor. Idea is to return two lists of components instead of calling this function twice */

export const TextCardList = ({ nodes, discipline, type }) => {
	let components = [];
	let previews = [];
	let number = 0;
	nodes.forEach((node) => {
		if (
			discipline === "all" ||
			(node.members[0]?.person.discipline &&
				node.members[0]?.person.discipline.name === discipline)
		) {
			number += 1;

			previews.push(
				<TextProjectPreview {...node} number={number} key={number} />
			);
		}
	});
	return previews;
};

